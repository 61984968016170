export const superviseVO = function () {
  return {
    obj: {
      id: null,
      orgId: null, //单位ID
      title: '', //标题
      content: '', //内容
      publisher: { //发布人
        id: null,
        name: ''
      },
      publishTime: '', //发布时间
      instanceId: null, //督办事项ID
      systemModule: '', //督办事项类型
      receiveType: '', //接受类型(all/dept/user)
      receiveIds: [], //接受对象IDS
      receiveNames: '', //接受对象名称
      ifReply: 0, //是否需要回复
      receiveNum: 0, //下达总数
      readNum: 0, //已经阅读数
      replyNum: 0, //已经回复数
      days: 0, //期限天数
      endTime: '', //结束时间
      state: 0
    },
    fileIds: [] //文件IDs
  }
}